import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CustomButtonGroupAsArrows from "./CustomButtonGroupAsArrows";

// images
// import s1_webp from "../../assets/Images/s1.webp";
// import s1_svg from "../../assets/Images/s1.svg";
// import s1_png from "../../assets/Images/s1.png";
// import s2_webp from "../../assets/Images/s2.webp";
// import s2_svg from "../../assets/Images/s2.svg";
// import s2_png from "../../assets/Images/s2.png";
// import s3_webp from "../../assets/Images/s3.webp";
// import s3_svg from "../../assets/Images/s3.svg";
// import s3_png from "../../assets/Images/s3.png";
// import s4_webp from "../../assets/Images/s4.webp";
// import s4_svg from "../../assets/Images/s4.svg";
// import s4_png from "../../assets/Images/s4.png";
// import s5_webp from "../../assets/Images/s5.webp";
// import s5_svg from "../../assets/Images/s5.svg";
// import s5_png from "../../assets/Images/s5.png";

import s1_webp from "../../assets/Images/screenshot1.webp";
// import s1_svg from "../../assets/Images/screenshot1.svg";
import s1_png from "../../assets/Images/screenshot1.png";
import s2_webp from "../../assets/Images/screenshot2.webp";
// import s2_svg from "../../assets/Images/screenshot2.svg";
import s2_png from "../../assets/Images/screenshot2.png";
import s3_webp from "../../assets/Images/screenshot3.webp";
// import s3_svg from "../../assets/Images/screenshot3.svg";
import s3_png from "../../assets/Images/screenshot3.png";
import s4_webp from "../../assets/Images/screenshot4.webp";
// import s4_svg from "../../assets/Images/screenshot4.svg";
import s4_png from "../../assets/Images/screenshot4.png";
import s5_webp from "../../assets/Images/screenshot5.webp";
// import s5_svg from "../../assets/Images/screenshot5.svg";
import s5_png from "../../assets/Images/screenshot5.png";

const Screenshots = () => {
  return (
    <>
      <div className="screenshots_container" id="screenshots">
        <div className="screenshots_wrapper">
          <div className="screenshots_top">
            <div className="screenshot_title">App Screenshots</div>
            <div className="screenshot_subtitle">
              Allow your kid to look back upon the love of both the parents.
              Both parents can add adorable moments of their kids on Hushbunny
              to cherish them forever
            </div>
          </div>
          <div style={{ position: "relative", marginTop: "3.5rem" }}>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-padding-bottom"
              customButtonGroup={<CustomButtonGroupAsArrows />}
              dotListClass=""
              focusOnSelect={false}
              infinite={true}
              itemClass="screenshot_image_div"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 5,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              slidesToSlide={1}
              swipeable
            >
              <div className="screenshot_image_div">
                <picture>
                  {/* Display SVG on larger screens and PNG on mobile */}
                  <source type="image/svg+xml" srcSet={"https://d1pk0wsheoualh.cloudfront.net/screenshot1.svg"} media="(min-width: 1024px)" />
                  <img src={s1_png} alt="app-screenshot-1" loading="lazy" />
                </picture>
              </div>
              <div className="screenshot_image_div">
                <picture>
                  <source type="image/svg+xml" srcSet={"https://d1pk0wsheoualh.cloudfront.net/screenshot2.svg"} media="(min-width: 1024px)" />
                  <img src={s2_png} alt="app-screenshot-2" loading="lazy" />
                </picture>
              </div>
              <div className="screenshot_image_div">
                <picture>
                  <source type="image/svg+xml" srcSet={"https://d1pk0wsheoualh.cloudfront.net/screenshot3.svg"} media="(min-width: 1024px)" />
                  <img src={s3_png} alt="app-screenshot-3" loading="lazy" />
                </picture>
              </div>
              <div className="screenshot_image_div">
                <picture>
                  <source type="image/svg+xml" srcSet={"https://d1pk0wsheoualh.cloudfront.net/screenshot4.svg"} media="(min-width: 1024px)" />
                  <img src={s4_png} alt="app-screenshot-4" loading="lazy" />
                </picture>
              </div>
              <div className="screenshot_image_div">
                <picture>
                  <source type="image/svg+xml" srcSet={"https://d1pk0wsheoualh.cloudfront.net/screenshot5.svg"} media="(min-width: 1024px)" />
                  <img src={s5_png} alt="app-screenshot-5" loading="lazy" />
                </picture>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};


export default Screenshots;
