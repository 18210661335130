import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import unsubscribe_img from "../../assets/Images/unsubscribe-img.png";
import axios from "axios";

const DownloadFile = () => {
    const [error, setError] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const apienv = searchParams.get('env');
    const prod = "https://api-prod.hushbunny.com"
    const dev = "https://api-dev.hushbunny.com"
    // const dev = "http://localhost:8000"
    useEffect(() => {
        const fetchAndDownload = async () => {
            try {
                if (!apienv) {
                    setError(true);
                }
                const api = (apienv === "prod") ? prod : dev
                const response = await axios.get(`${api}/fileTransfer/v1/downloadZip?token=${token}`, {
                    responseType: 'json', // Expecting the URL as a response
                });

                // Assuming the response contains the download URL directly
                if (response.data && response.data.url) {
                    // Redirecting to the download URL
                    window.location.href = response.data.url;

                    // Navigate back to the homepage after 1 second
                    setTimeout(() => navigate('/'), 1000);
                } else {
                    setError(true);
                }
            } catch (error) {
                console.error(error); // Log error for debugging
                setError(true);
            }
        };

        fetchAndDownload();
    }, [location.search, navigate, token]);

    if (error) {
        return (
            <>
                <Navbar legal={true} />
                <div className="unsubscribe_container">
                    <div className="unsubscribe_wrapper">
                        <div className="unsubscribe_body">
                            <div className="unsubscribe_title">This link has expired.</div>
                            <img src={unsubscribe_img} alt="unsubscribe-img" />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <div className="unsubscribe_container">
                <Navbar legal={true} />
                <div className="unsubscribe_wrapper">
                    <div className="unsubscribe_body">
                        <p>Preparing your data for download...</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DownloadFile;
