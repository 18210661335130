import React from "react";

//components
import Hero from "../../components/Hero/Hero";
import Features from "../../components/Features/Features";
import Screenshots from "../../components/Screenshots/Screenshots";
import Gift from "../../components/Gift/Gift";
import Footer from "../../components/Footer/Footer";
import AboutUs from "../AboutUs/AboutUs";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Features />
      <Screenshots />
      <Gift />
      <Footer />
    </>
  );
};

export default LandingPage;
